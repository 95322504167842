import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function Headline({
  headline,
  level,
  fontFamily,
  fontColor,
  isUppercase,
  isBold,
}) {
  const Element = level
  const className = classNames(fontFamily, fontColor, {
    'text-uppercase': isUppercase,
    'font-weight-bold': isBold,
  })

  return (
    <div className="container">
      {headline && <Element className={className}>{headline}</Element>}
    </div>
  )
}

Headline.propTypes = {
  headline: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3']),
  fontFamily: PropTypes.oneOf([
    'font-family-FiraSans',
    'font-family-BuckwheatTC-Regular',
    'font-family-OpenSans',
    'font-family-DancingScript',
    'font-family-Bitter',
    'font-family-Mansalva',
    'font-family-Roboto',
    'font-family-RobotoSlab',
    'font-family-SourceSansPro',
    'font-family-Gotcha',
    'font-family-DidotLTPro',
    'font-family-HelveticaNeue',
  ]),
  fontColor: PropTypes.oneOf(['text-primary', 'text-logo-color', 'text-dark']),
  isBold: PropTypes.bool,
  isUppercase: PropTypes.bool,
}

Headline.defaultProps = {
  headline: null,
  level: 'h1',
  fontFamily: null,
  fontColor: null,
  isBold: false,
  isUppercase: false,
}

export { default as headlineVariants } from './variants'
