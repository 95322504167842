import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { truncateString, useConfiguration } from '../../../utils'
import { ConditionalLink, RawHtml } from '../../index'

const Item = ({ titleColor, titleFont, titleLevel, bgColor, align, row }) => {
  const { image, preTitle, title, subTitle, icons } = row
  const { getImageLink } = useConfiguration()
  const imageSrc = getImageLink({ source: image })
  const Element = titleLevel

  const titleClasses = classNames('pt-2 pt-lg-3 mb-0', titleFont, {
    [`text-${titleColor}`]: !!titleColor,
    [`text-${align}`]: !!align,
  })

  return (
    <div
      className={`row mx-0 bg-${bgColor} justify-content-lg-center align-items-lg-center mb-3 mb-lg-0`}
    >
      <div className="image-text-columns__col-img col-12 col-lg-6 p-0 text-center">
        {image && <img src={imageSrc} alt="img-link" />}
      </div>
      <div className="image-text-columns__col-text col-12 col-lg-6 p-0">
        <div className={`text-${align}`}>
          {preTitle && (
            <span className="d-block text-gray-2 text-uppercase font-weight-semi-bold">
              {preTitle}
            </span>
          )}
          {title && <Element className={titleClasses}>{title}</Element>}
          {subTitle && (
            <RawHtml
              element="p"
              html={truncateString(subTitle, 420)}
              className="pt-2 pt-lg-3 mb-0"
              title={subTitle}
            />
          )}
          {icons?.length > 0 && (
            <div className="pt-2 pt-lg-3">
              {icons.slice(0, 4).map((iconItem, iconIndex) => {
                const iconSrc = getImageLink({ source: iconItem.icon })
                return (
                  <ConditionalLink
                    key={iconIndex}
                    className="image-text-columns__icon"
                    href={iconItem.iconLink}
                    fallbackElement="span"
                  >
                    <img src={iconSrc} alt="icon" />
                  </ConditionalLink>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  image: PropTypes.string,
  preTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.object),
}

Item.defaultProps = {
  image: '',
  preTitle: '',
  title: '',
  subTitle: '',
  icons: [],
}

export default Item
